                            
                            
          
                            
                            
          

const defaultLang = {};
defaultLang[process.env.VUE_APP_DEFAULT_LANG] = messages;

export { defaultLang };